<template>
  <div class="home">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ name: 'Home' }">{{
        app_name
      }}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'UsersManagement' }">{{
        $t("buttons.user_management")
      }}</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-block">
      <div class="page-block-header">
        <div class="content-title">
          <p>{{ $t("buttons.user_management") }}</p>
        </div>
      </div>

      <div class="page-block-content">
        <div></div>
        <div class="results-part">
          {{ $t("usercenter.users_all") }}: {{ n_users }}
          <el-button
            type="text"
            icon="el-icon-refresh"
            @click="refreshUsersList"
          ></el-button>
        </div>
      </div>
    </div>
    <div class="page-content-fill" v-loading="content_in_processing">
      <el-table :data="users_list" stripe style="width: 100%">
        <el-table-column
          prop="id"
          fixed="left"
          :label="$t('usercenter.id')"
          width="80"
        >
        </el-table-column>
        <el-table-column :label="$t('usercenter.name')" width="80" prop="name">
        </el-table-column>
        <el-table-column
          :label="$t('dashboard.home.disk_space_usage')"
          width=""
        >
          <template slot-scope="scope">
            {{ (scope.row.datahome_size * 1e-9).toPrecision(3) }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('usercenter.all_tasks')"
          width=""
          prop="task_all"
        >
        </el-table-column>
        <el-table-column
          :label="$t('usercenter.completed_tasks')"
          width=""
          prop="task_finished"
        >
        </el-table-column>
        <el-table-column
          :label="$t('usercenter.running_tasks')"
          width=""
          prop="task_running"
        >
        </el-table-column>
        <el-table-column
          :label="$t('usercenter.waiting_tasks')"
          width=""
          prop="task_waiting"
        >
        </el-table-column>
        <el-table-column
          :label="$t('usercenter.failed_tasks')"
          width=""
          prop="task_failed"
        >
        </el-table-column>
        <el-table-column
          :label="$t('buttons.operation')"
          fixed="right"
          width="250"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleZoomInUserTasks(scope.row.id)"
              >{{ $t("taskcenter.task_info") }}</el-button
            >
            <el-popconfirm
              :title="
                $t('usercenter.del_id_prefix') +
                  scope.row.id +
                  $t('usercenter.del_id_suffix')
              "
              :confirmButtonText="$t('buttons.confirm')"
              :cancelButtonText="$t('buttons.cancel')"
              @onConfirm="handleDeleteUser(scope.row.id)"
            >
              <el-button
                slot="reference"
                size="mini"
                type="danger"
                style="margin-left: 10px"
                :disabled="scope.row.status === 'running'"
                >{{ $t("buttons.delete") }}</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="prev, pager, next"
        :total="n_users"
        :page-size="n_users_per_page"
        :current-page.sync="n_page"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import apiURL from "@/data/api";
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "UsersManagement",
  components: {},
  data() {
    return {
      users_list: [],
      n_users: 0,
      n_users_per_page: 8,
      n_page: 1,

      user_id_show: "",
    };
  },
  watch: {
    user_id_show(nv) {
      this.refreshUsersList();
      console.log(nv);
    },
  },
  beforeMount() {
    this.switchDefaultPage("/dashboard/users-management");
  },
  computed: {
    ...mapGetters(["headers4reqs", "user_dict"]),
    ...mapState({
      user_name: (state) => state.user.statistics.name,
      user_statistics: (state) => state.user.statistics,
      admin_name: (state) => state.admin_name,
      app_name: (state) => state.app_name,
    }),
  },
  mounted() {
    this.refreshUsersList();
  },
  methods: {
    ...mapActions(["switchDefaultPage"]),
    user_id2name(i) {
      if (i in this.user_dict) {
        return this.user_dict[i];
      } else {
        return "加载中";
      }
    },
    async getUsersList() {
      var params = {
        skip: (this.n_page - 1) * this.n_users_per_page,
        limit: this.n_users_per_page,
      };
      if (this.user_id_show !== "") {
        params.user_id = this.user_id_show;
      }
      await this.$get_api(
        apiURL.user.all,
        null,
        this.headers4reqs,
        params
      ).then((res) => {
        this.n_users = res.n_users;
        this.users_list = res.users;
      });
    },
    async handlePageChange(page_id) {
      console.log(page_id);
      await this.getUsersList();
    },
    async refreshUsersList() {
      this.n_page = 1;
      this.content_in_processing = true;
      await this.getUsersList();
      this.content_in_processing = false;
    },
    handleDeleteUser(user_id) {
      if (user_id !== null) {
        this.$delete_api(
          apiURL.user.details.replace("user_id", user_id),
          null,
          this.headers4reqs
        ).then((res) => {
          this.refreshUsersList();
          return res;
        });
      }

      return user_id;
    },
    handleZoomInUserTasks(id) {
      this.$router.push({ name: "TaskList", params: { user_id_show: id } });
    },
  },
  filters: {
    toLocalTime(t) {
      return moment.utc(t).local();
    },
    formatLocalTime(t) {
      return t.format("YYYY-MM-DD HH:mm:ss");
    },
  },
  //
};
</script>
<style lang="scss" scoped>
@import "@/style/main";
</style>
